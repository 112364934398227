/* You can add global styles to this file, and also import other style files */
//@import url("https://fonts.googleapis.com/css2?family=Owners+XWide:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

@import url("https://use.typekit.net/wdp5qon.css");
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1310px,
);
$min-contrast-ratio: 3;

$white: #fff;

$primary: #fd28ae;
$secondary: #4400cc;
$dark: #001a2d;

@import "bootstrap/scss/functions";

:root {
  --white: #{$white};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --dark: #{$dark};
}

$custom-colors: (
  "white": $white,
  "primary": $primary,

  "secondary": $secondary,
  "dark": $dark,
);

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// scss-docs-start import-stack
// Configuration

$theme-colors: map-merge($theme-colors, $custom-colors);
// $colors: map-merge($colors, $custom-colors);
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/progress";
// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

@import "@swimlane/ngx-datatable/index.scss";

@import "@swimlane/ngx-datatable/themes/bootstrap.scss";
body {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: auto;
  color: $white;
  background-color: $dark;

  background-color: $dark;
}

p,
label {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
.text-heading {
  font-family: "owners-xwide", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}
h1 {
  font-size: 64px;
}

h3 {
  font-size: 22px;
}
.btn {
  border-radius: 30px;
  border-width: 2px;
  padding: 12px 20px 12px 20px;
  text-transform: uppercase;
}

.btn-medium {
  height: 60px;
}
.text-small {
  font-size: 16px;
}

.text-medium {
  font-size: 18px;
}

.text-underline {
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}

.form-control {
  border-radius: 30px;

  &.dark {
    border: none;
  }
}
select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.card,
.card-header,
.br,
.rounded {
  border-radius: 12px !important;
}

ngx-datatable {
  color: $dark;
}
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}
.action-button {
  border: none !important;
  background: none !important;
}

#qb-posts {
  h1 {
    color: var(--dark) !important;
    text-transform: uppercase;
  }
}
